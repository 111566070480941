import React, { useRef } from 'react';
import { Link } from 'gatsby';
import { Transition } from '@headlessui/react';
import clsx from 'clsx';

import MobileNavigation from './MobileNavigation';
import { ChevronDown, Menu } from '../assets/Icons';
import useBoolean from '../hooks/useBoolean';
import useOnClickOutside from '../hooks/useOnClickOutside';
import logo from '../assets/logo.svg';
import useServiceList from '../hooks/useServiceList';

interface Props {
  currentPath?: string;
  light: boolean;
  siteTitle: string;
}

const Header: React.FC<Props> = ({ currentPath, light, siteTitle = '' }) => {
  const $flyoutMenuRef = useRef();
  const [
    isMobileMenuOpen,
    { setTrue: openMobileMenu, setFalse: closeMobileMenu },
  ] = useBoolean();
  const [flyoutMenuOpen, { toggle, setFalse: closeFlyoutMenu }] = useBoolean();
  const services = useServiceList();

  useOnClickOutside($flyoutMenuRef, closeFlyoutMenu);

  const buttonClassnames =
    'bg-transparent p-2 inline-flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500';

  const linkColor = light
    ? 'text-blue-800 hover:text-blue-500'
    : 'text-white hover:text-blue-100';

  return (
    <div
      className={clsx([
        'relative z-20',
        !light && 'bg-blue-800',
        light && 'border-b border-blue-300 border-opacity-10',
      ])}
    >
      {/* Mobile menu */}
      <MobileNavigation
        buttonClassname={buttonClassnames}
        closeMenu={closeMobileMenu}
        currentPath={currentPath}
        isOpen={isMobileMenuOpen}
      />

      <div className="mx-auto max-w-7xl px-4 sm:px-6">
        <div className="flex items-center justify-between py-3">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <Link to="/">
              <img
                alt={`${siteTitle} logo`}
                className="h-12 w-auto sm:h-16"
                src={logo}
              />
            </Link>
          </div>

          <div className="-my-2 -mr-2 md:hidden">
            <button
              className={clsx(buttonClassnames, linkColor)}
              onClick={openMobileMenu}
              type="button"
            >
              <span className="sr-only">Open menu</span>
              <Menu aria-hidden="true" className="h-6 w-6" />
            </button>
          </div>

          <nav className="hidden space-x-10 md:flex">
            <div className="relative z-20" ref={$flyoutMenuRef}>
              <button
                className={clsx([
                  'inline-flex items-center text-base font-medium focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2',
                  linkColor,
                ])}
                onClick={toggle}
                type="button"
              >
                <span>Djelatnosti</span>
                <span className="ml-2">
                  <span className="sr-only">Open dropdown</span>
                  <ChevronDown aria-hidden="true" className="h-5 w-5" />
                </span>
              </button>

              <Transition
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
                show={flyoutMenuOpen}
                unmount={false}
              >
                <div className="absolute -mr-4 mt-3 w-screen max-w-md transform px-2 sm:px-0 md:right-0 lg:ml-0">
                  <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                    <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                      {services.map((service) => (
                        <Link
                          className="-m-3 flex items-start rounded-lg p-3 hover:bg-gray-100"
                          key={service.href.current}
                          to={`/${service.href.current}`}
                        >
                          <span
                            className={clsx(
                              'text-base font-medium',
                              currentPath === `/${service.href.current}`
                                ? 'text-blue-600'
                                : 'text-gray-900',
                            )}
                          >
                            {service.label}
                          </span>
                        </Link>
                      ))}
                    </div>
                  </div>
                </div>
              </Transition>
            </div>

            <Link
              className={clsx(['text-base font-medium', linkColor])}
              to="/#kontakt"
            >
              Kontakt
            </Link>

            <Link
              className={clsx(['text-base font-medium', linkColor])}
              to="/projekti/"
            >
              Referenc lista
            </Link>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Header;
