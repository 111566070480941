import React from 'react';
import { Link } from 'gatsby';
import { Transition } from '@headlessui/react';
import clsx from 'clsx';

import { Close } from '../assets/Icons';
import logo from '../assets/logo.svg';
import useServiceList from '../hooks/useServiceList';

interface Props {
  buttonClassname: string;
  closeMenu: () => void;
  currentPath?: string;
  isOpen: boolean;
}

const MobileNavigation: React.FC<Props> = ({
  buttonClassname,
  closeMenu,
  currentPath,
  isOpen,
}) => {
  const services = useServiceList();

  return (
    <Transition
      enter="transition duration-200 ease-out"
      enterFrom="opacity-0 scale-95"
      enterTo="opacity-100 scale-100"
      leave="transition duration-100 ease-in"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-95"
      show={isOpen}
      unmount={false}
    >
      <div className="absolute inset-x-0 top-0 z-20 origin-top-right transform p-2 transition md:hidden">
        <div className="divide-y-2 divide-gray-50 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
          <div className="px-5 pb-6 pt-5">
            <div className="flex items-center justify-between">
              <img alt="Keso gradnja logo" className="h-11 w-auto" src={logo} />

              <div className="-mr-2">
                <button
                  className={clsx(
                    buttonClassname,
                    'text-gray-400 hover:text-gray-600',
                  )}
                  onClick={closeMenu}
                  type="button"
                >
                  <span className="sr-only">Close menu</span>
                  <Close aria-hidden="true" className="h-6 w-6" />
                </button>
              </div>
            </div>

            <div className="mt-10">
              <nav className="grid gap-y-8">
                {services.map((service) => (
                  <Link
                    className="-m-3 flex items-start rounded-lg p-3 hover:bg-gray-100"
                    key={service.href.current}
                    to={`/${service.href.current}`}
                  >
                    <span
                      className={clsx(
                        'text-base font-medium',
                        currentPath === `/${service.href.current}`
                          ? 'text-blue-600'
                          : 'text-gray-900',
                      )}
                    >
                      {service.label}
                    </span>
                  </Link>
                ))}
              </nav>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  );
};

export default MobileNavigation;
